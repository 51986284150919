//
//
//
//
//
//
//

import * as echarts from 'echarts'
export default {
     props:["TypeData"],
  data() {
    return {
     data:[],
    }
  },
  watch:{
    "TypeData"(val){
      this.data = val
      this.inits()
    },
  },
  data() {
    return {
      data:[],
    }
  },

  methods: {
    inits() {
      let chartDom = document.getElementById('mysChartPieB')
      let myChart = echarts.init(chartDom)
      myChart.clear()
      myChart.setOption({
        title: {
          subtext: '报障类型',
          left: 'center',
          subtextStyle: {
            fontSize : 16
          }
        },
         tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: 'bottom',
        },
        series: [
          {
            type: 'pie',
            radius: ['55%', '70%'],
            data:this.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            markPoint: {
              symbol:
                'image://https://img02.mockplus.cn/idoc/xd/2021-11-03/6943c0bb-16a6-4507-bc98-c382fdfd447b.png',
              symbolSize: 35,
              data: [
                //跟你的仪表盘的中心位置对应上，颜色可以和画板底色一样
                {
                  x: 'center',
                  y: 'center',
                },
              ],
            },
            label : {
              show : false
            }
          },
        ],
      },)
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {_getCounts,_getcreateOrder} from "@/api/maintenance";
export default {
  data() {
    return {
      dialogVisible: false,
      btnDisab:false,
      form:{
          planId:"",
          currentCount:""
      },
      list:[]
    };
  },
  methods: {
    init(planId) {
      this.dialogVisible = true;
      this.form.planId = planId
      this.getCounts({planId:planId})
    },
    // 下拉数据
    getCounts(from){
        _getCounts(from).then((res)=>{
            res.data.forEach((item,index)=>{
              if(index != '0'){
                item.disabled = true
                item.name = `${item.viewCount}` +" "+ `${item.dateTime}`
              }else{
                item.disabled = false
                item.name = `${item.viewCount}` +" "+ `${item.dateTime}`
              }
            })
          this.list = res.data
        })
    },
    adduser(){
      this.btnDisab = true
        _getcreateOrder(this.form).then((res) => {
        if (res.code == "1") {
          this.btnDisab = false;
          this.$message({message:res.msg,duration : 1000});
          this.$router.push({
            path: "/main/maintenance/order",
            query: { orderType: "30" },
          });
        }else{
          this.btnDisab = false
        }
      }).catch(()=>{
        this.btnDisab = false
      });
    },
   
    close() {
      this.form = {};
    },
  },
};

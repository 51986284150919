//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _setequtment ,_settemplateEditInfo,_settemplateEquipmentEdit} from "@/api/maintenance";
export default {
  name: "Ordertemplate",
  data() {
    return {
      activeName:"",
      // 搜索条件
      name: "",
      btnDisab:false,
      // 数据数组
      content: [
        {
          contentName: "",
          standard: "",
        },
      ],
      contents:"新增维保设备模板",
      disab:false
     };
  },
  created(){
    this.contents = this.$route.query.type == "2" ? "修改模板": "新增模板"
    this.disab = this.$route.query.type == "2" ? true :false
      if(this.$route.query.type == '2'){
      _settemplateEditInfo({id:this.$route.query.id}).then((res)=>{
        this.name = res.data.name
        this.content = res.data.content
      })
    }
  },
  methods: {
    // 返回按钮事件
    goBack() {
      this.$router.push("/main/maintenance/mode");
    },
    onSubmit() {
      let from = {
        name: this.name,
        content: this.content,
        id:this.$route.query.id
      };
      this.btnDisab = true
      if(this.$route.query.type == "2"){//修改
        _settemplateEquipmentEdit(from).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.btnDisab = false
            this.$router.push("/main/maintenance/mode");
          }else{
            this.btnDisab = false
          }
        }).catch(()=>{
          this.btnDisab = false
        });
      }else{
        let from = {
        name: this.name,
        content: this.content,
      };
      _setequtment(from).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.btnDisab = false
          this.$router.push("/main/maintenance/mode");
        }else{
          this.btnDisab = false
        }
      }).catch(()=>{
        this.btnDisab = false
      });
      }
      
    },
    // 新增维保服务按钮
    addservice() {
      this.content.unshift({
        contentName: "",
        standard: "",
      });
    },
    // 移除的事件
    remove(index) {
      this.content.splice(index, 1);
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sbox",attrs:{"id":"sbox"}},[_c('div',{staticClass:"box_top"},[_c('el-page-header',{staticClass:"page_head",attrs:{"content":"维保计划"},on:{"back":_vm.goBack}})],1),_c('div',{staticClass:"box_from"},[_c('el-form',{ref:"dynamicValidateForm",staticStyle:{"margin":"0"},attrs:{"disabled":_vm.viewBtn,"model":_vm.formsdes,"label-width":"110px"}},[_c('el-row',{staticStyle:{"margin":"0"},attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{staticStyle:{"margin-bottom":"0px"},attrs:{"prop":"name","rules":{
              required: true,
              message: '请输入计划名称',
              trigger: 'blur',
            },"label":"计划名称"}},[_c('el-input',{staticStyle:{"width":"220px"},attrs:{"disabled":_vm.gitSwith},model:{value:(_vm.formsdes.name),callback:function ($$v) {_vm.$set(_vm.formsdes, "name", $$v)},expression:"formsdes.name"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"startTime","rules":{
              required: true,
              message: '请输入选择日期',
              trigger: 'change',
            },"label":"开始时间"}},[_c('el-date-picker',{attrs:{"disabled":_vm.gitSwith,"type":"date","placeholder":"选择日期","value-format":"yyyy-MM-ddTHH:mm:SS.000Z","picker-options":_vm.newPickerOptions},model:{value:(_vm.formsdes.startTime),callback:function ($$v) {_vm.$set(_vm.formsdes, "startTime", $$v)},expression:"formsdes.startTime"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"endTime","rules":{
              required: true,
              message: '请输入结束时间',
              trigger: 'change',
            },"label":"结束时间"}},[_c('el-date-picker',{attrs:{"type":"date","value-format":"yyyy-MM-ddTHH:mm:SS.000Z","placeholder":"选择日期","picker-options":_vm.pickerOptions},model:{value:(_vm.formsdes.endTime),callback:function ($$v) {_vm.$set(_vm.formsdes, "endTime", $$v)},expression:"formsdes.endTime"}})],1)],1)],1),_c('el-row',{staticStyle:{"margin":"0"},attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"自定义次数","prop":"count","rules":{
              required: true,
              message: '请输入自定义次数',
              trigger: 'blur',
            }}},[_c('el-input',{staticStyle:{"width":"220px"},attrs:{"oninput":"value=value.replace(/[^0-9]/g,'')","placeholder":"请输入"},model:{value:(_vm.formsdes.count),callback:function ($$v) {_vm.$set(_vm.formsdes, "count", $$v)},expression:"formsdes.count"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"respUid","rules":{
              required: true,
              message: '请选择需求方负责人',
              trigger: 'change',
            },"label":"需求方负责人"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择需求方负责人"},model:{value:(_vm.formsdes.respUid),callback:function ($$v) {_vm.$set(_vm.formsdes, "respUid", $$v)},expression:"formsdes.respUid"}},_vm._l((_vm.headlist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1)],1)],1),(this.$route.query.type != '1')?_c('div',{staticStyle:{"margin-left":"15px"}},[_c('TimeLine',{ref:"timeline",on:{"timedatas":_vm.timedatas,"myBls":_vm.myBls}})],1):_vm._e(),(this.$route.query.type == '1')?_c('div',{staticStyle:{"margin-left":"15px"}},[_c('Viewtimeline',{ref:"viewtimeline"})],1):_vm._e(),_c('div',{staticClass:"maintenance"},[_c('div',{staticClass:"maintenance_top"},[_vm._v(" 网点维保"),(!_vm.viewBtn)?_c('img',{staticClass:"img",attrs:{"src":require('@/assets/img/add.png'),"alt":""},on:{"click":function($event){return _vm.addBranches()}}}):_vm._e()]),_vm._l((_vm.contentList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"maintenance_top_leader"},[(!_vm.viewBtn)?_c('div',{staticClass:"maintenance_top_leader_del"},[_c('img',{attrs:{"src":require('@/assets/img/ico_gb2.png'),"alt":""},on:{"click":function($event){return _vm.delBranches(index)}}})]):_vm._e(),_c('div',{staticClass:"maintenance_top_leader_div"},[_c('el-form',{ref:'item' + index,refInFor:true,staticClass:"demo-form-inline-aste",attrs:{"disabled":_vm.viewBtn,"inline":true,"model":_vm.contentList[index]}},[_c('el-form-item',{attrs:{"label":"部门名称","prop":".deptId","rules":{
                required: true,
                message: '请选择部门',
                trigger: 'change',
              }}},[_c('el-select',{attrs:{"clearable":"","placeholder":"部门名称"},on:{"change":function($event){return _vm.changeServe(index, $event)}},model:{value:(item.deptId),callback:function ($$v) {_vm.$set(item, "deptId", $$v)},expression:"item.deptId"}},_vm._l((_vm.curcityist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"服务商名称","prop":".spDid","rules":{
                required: true,
                message: '请选择服务商',
                trigger: 'change',
              }}},[_c('el-select',{attrs:{"clearable":"","placeholder":"服务商名称"},on:{"change":function($event){return _vm.changeNewServe(index, $event)}},model:{value:(item.spDid),callback:function ($$v) {_vm.$set(item, "spDid", $$v)},expression:"item.spDid"}},_vm._l((item.uesrlistsnew),function(item){return _c('el-option',{key:item.spDid,attrs:{"label":item.spDname,"value":item.spDid}})}),1)],1),_c('el-form-item',{attrs:{"label":"选择模板","prop":"templateId"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择模板"},on:{"change":function($event){return _vm.changetelmpt($event, index)}},model:{value:(item.templateId),callback:function ($$v) {_vm.$set(item, "templateId", $$v)},expression:"item.templateId"}},_vm._l((_vm.repairoptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1),_c('div',[_c('p',{staticClass:"maintenance_top_leader_txt"},[_vm._v("网点服务")]),_c('el-form',{ref:'items' + index,refInFor:true,attrs:{"model":item.serviceList[0],"disabled":true}},[_c('el-table',{attrs:{"data":item.serviceList[0].datas,"row-style":{ color: '#4f5878' },"cell-style":{ background: 'revert' },"header-cell-style":_vm.tableHeaderColor,"border":""}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","align":"center","width":"150px"}}),_c('el-table-column',{attrs:{"label":"服务内容","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-form-item',{attrs:{"prop":("datas[" + (scope.$index) + "].content"),"rules":{
                        required: true,
                        message: '请输入服务内容',
                        trigger: 'blur',
                      }}},[_c('el-input',{staticClass:"inputinsns",model:{value:(scope.row.content),callback:function ($$v) {_vm.$set(scope.row, "content", $$v)},expression:"scope.row.content"}})],1)]]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"name","label":"服务标准","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-form-item',{attrs:{"prop":("datas[" + (scope.$index) + "].standards"),"rules":{
                        required: true,
                        message: '请输入服务标准',
                        trigger: 'blur',
                      }}},[_c('el-input',{staticClass:"inputinsns",model:{value:(scope.row.standards),callback:function ($$v) {_vm.$set(scope.row, "standards", $$v)},expression:"scope.row.standards"}})],1)]]}}],null,true)})],1)],1)],1),(false)?_c('div',{staticClass:"maintenance_top"},[_vm._v(" 网点设备"),(!_vm.viewBtn)?_c('img',{staticClass:"img",attrs:{"src":require('@/assets/img/add.png'),"alt":""},on:{"click":function($event){return _vm.addcontent(index)}}}):_vm._e()]):_vm._e(),_vm._l((item.content),function(oneitem,indexs){return _c('div',{key:indexs},[_c('div',{staticClass:"maintenance_top_content"},[(!_vm.viewBtn)?_c('div',{staticClass:"maintenance_top_content_del"},[_c('img',{attrs:{"src":require('@/assets/img/ico_gb2.png'),"alt":""},on:{"click":function($event){return _vm.delContent(index, indexs)}}})]):_vm._e(),_c('el-form',{ref:'oneitem' + indexs,refInFor:true,staticClass:"demo-form-inline_form",attrs:{"disabled":_vm.viewBtn,"inline":true,"model":oneitem}},[_c('el-form-item',{attrs:{"label":"设备名称","prop":".name","rules":{
                  required: true,
                  message: '请输入设备名称',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(oneitem.name),callback:function ($$v) {_vm.$set(oneitem, "name", $$v)},expression:"oneitem.name"}})],1),_c('el-form-item',{attrs:{"label":"设备厂商","prop":".firm","rules":{
                  required: true,
                  message: '请输入设备厂商',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(oneitem.firm),callback:function ($$v) {_vm.$set(oneitem, "firm", $$v)},expression:"oneitem.firm"}})],1),_c('el-form-item',{attrs:{"label":"设备型号","prop":".type","rules":{
                  required: true,
                  message: '请输入设备型号',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(oneitem.type),callback:function ($$v) {_vm.$set(oneitem, "type", $$v)},expression:"oneitem.type"}})],1),_c('el-form-item',{attrs:{"label":"设备单价","prop":".price","rules":{
                  required: true,
                  message: '请输入设备单价',
                  trigger: 'blur',
                }}},[_c('el-input',{attrs:{"oninput":"value=value.replace(/[^0-9.]/g,'')"},model:{value:(oneitem.price),callback:function ($$v) {_vm.$set(oneitem, "price", $$v)},expression:"oneitem.price"}})],1),_c('el-form-item',{attrs:{"label":"设备模板","prop":"templateId"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择模板"},on:{"change":function($event){return _vm.changeequip($event, index, indexs)}},model:{value:(oneitem.templateId),callback:function ($$v) {_vm.$set(oneitem, "templateId", $$v)},expression:"oneitem.templateId"}},_vm._l((_vm.templateoptions),function(itemss){return _c('el-option',{key:itemss.id,attrs:{"label":itemss.name,"value":itemss.id}})}),1)],1)],1),_c('el-form',{ref:'oneitemss' + indexs,refInFor:true,attrs:{"disabled":true,"model":oneitem}},[_c('el-table',{attrs:{"data":oneitem.serviceStandarder,"row-style":{ color: '#4f5878' },"header-cell-style":_vm.tableHeaderColor,"cell-style":{ background: 'revert', padding: '10' },"border":""}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","align":"center","width":"150px"}}),_c('el-table-column',{attrs:{"label":"服务内容","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-form-item',{attrs:{"prop":("serviceStandarder[" + (scope.$index) + "].contentName"),"rules":{
                          required: true,
                          message: '请输入服务内容',
                          trigger: 'blur',
                        }}},[_c('el-input',{staticClass:"inputinsns",model:{value:(scope.row.contentName),callback:function ($$v) {_vm.$set(scope.row, "contentName", $$v)},expression:"scope.row.contentName"}})],1)]]}}],null,true)}),_c('el-table-column',{attrs:{"label":"服务标准","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-form-item',{attrs:{"prop":("serviceStandarder[" + (scope.$index) + "].standard"),"rules":{
                          required: true,
                          message: '请输入服务标准',
                          trigger: 'blur',
                        }}},[_c('el-input',{staticClass:"inputinsns",model:{value:(scope.row.standard),callback:function ($$v) {_vm.$set(scope.row, "standard", $$v)},expression:"scope.row.standard"}})],1)]]}}],null,true)})],1)],1)],1)])})],2)])})],2),_c('div',{staticClass:"freeEquipment"},[_c('div',{staticClass:"freeEquipmentAdd"},[_vm._v(" 设备维保"),(!_vm.viewBtn)?_c('img',{staticClass:"img",attrs:{"src":require('@/assets/img/add.png'),"alt":""},on:{"click":function($event){return _vm.imgAdd()}}}):_vm._e()]),_vm._l((_vm.freeEquipments),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"maintenance_top_free"},[_c('div',{staticClass:"maintenance_top_free_del"},[(!_vm.viewBtn)?_c('img',{attrs:{"src":require('@/assets/img/ico_gb2.png'),"alt":""},on:{"click":function($event){return _vm.imgDel(index)}}}):_vm._e()]),_c('div',[_c('el-form',{ref:'itemasas' + index,refInFor:true,attrs:{"disabled":_vm.viewBtn,"model":item,"inline":true}},[_c('el-row',{staticStyle:{"margin":"0","text-align":"left","padding-top":"15px"},attrs:{"gutter":24}},[_c('el-col',[_c('el-form-item',{attrs:{"label":"服务商名称","prop":".spDid","rules":{
                    required: true,
                    message: '请选择服务商名称',
                    trigger: 'change',
                  }}},[_c('el-select',{attrs:{"clearable":"","placeholder":"服务商名称"},on:{"change":function($event){return _vm.changeNew(index, $event)}},model:{value:(item.spDid),callback:function ($$v) {_vm.$set(item, "spDid", $$v)},expression:"item.spDid"}},_vm._l((_vm.freeEquipmentSP),function(item){return _c('el-option',{key:item.spDid,attrs:{"label":item.spDname,"value":item.spDid}})}),1)],1)],1)],1)],1)],1),_c('div',{staticClass:"freeEquipmentAdd"},[_vm._v(" 设备"),(!_vm.viewBtn)?_c('img',{staticClass:"img",attrs:{"src":require('@/assets/img/add.png'),"alt":""},on:{"click":function($event){return _vm.maintenanceAdd(index)}}}):_vm._e()]),_vm._l((item.equipments),function(oneitem,indexs){return _c('div',{key:indexs},[_c('div',{staticClass:"maintenance_top_add_com"},[_c('div',{staticClass:"maintenance_top_add_com_del"},[(!_vm.viewBtn)?_c('img',{attrs:{"src":require('@/assets/img/ico_gb2.png'),"alt":""},on:{"click":function($event){return _vm.maintenanceDel(index, indexs)}}}):_vm._e()]),_c('el-form',{ref:'itemsb' + indexs,refInFor:true,staticClass:"demo-form-inline_form",staticStyle:{"text-align":"left","padding":"15px"},attrs:{"disabled":_vm.viewBtn,"model":oneitem,"inline":true}},[_c('el-form-item',{attrs:{"label":"设备名称","prop":".name","rules":{
                  required: true,
                  message: '请输入设备名称',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(oneitem.name),callback:function ($$v) {_vm.$set(oneitem, "name", $$v)},expression:"oneitem.name"}})],1),_c('el-form-item',{attrs:{"label":"设备数量","prop":".eNumber","rules":{
                  required: true,
                  message: '请输入设备数量',
                  trigger: 'blur',
                }}},[_c('el-input',{attrs:{"oninput":"value=value.replace(/[^0-9]/g,'')"},model:{value:(oneitem.eNumber),callback:function ($$v) {_vm.$set(oneitem, "eNumber", $$v)},expression:"oneitem.eNumber"}})],1),_c('el-form-item',{attrs:{"label":"设备模板","prop":"templateId"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择模板"},on:{"change":function($event){return _vm.changtempl($event, index, indexs)}},model:{value:(oneitem.templateId),callback:function ($$v) {_vm.$set(oneitem, "templateId", $$v)},expression:"oneitem.templateId"}},_vm._l((_vm.templateoptions),function(itemss){return _c('el-option',{key:itemss.id,attrs:{"label":itemss.name,"value":itemss.id}})}),1)],1)],1),_c('el-form',{ref:'oneitemsa' + indexs,refInFor:true,attrs:{"disabled":true,"model":oneitem}},[_c('el-table',{attrs:{"data":oneitem.serviceStandarder,"row-style":{ color: '#4f5878' },"header-cell-style":_vm.tableHeaderColor,"cell-style":{ background: 'revert', padding: '10' },"border":""}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","align":"center","width":"150px"}}),_c('el-table-column',{attrs:{"label":"服务内容","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-form-item',{attrs:{"prop":("serviceStandarder[" + (scope.$index) + "].contentName"),"rules":{
                          required: true,
                          message: '请输入服务内容',
                          trigger: 'blur',
                        }}},[_c('el-input',{staticClass:"inputinsns",model:{value:(scope.row.contentName),callback:function ($$v) {_vm.$set(scope.row, "contentName", $$v)},expression:"scope.row.contentName"}})],1)]]}}],null,true)}),_c('el-table-column',{attrs:{"label":"服务标准","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-form-item',{attrs:{"prop":("serviceStandarder[" + (scope.$index) + "].standard"),"rules":{
                          required: true,
                          message: '请输入服务标准',
                          trigger: 'blur',
                        }}},[_c('el-input',{staticClass:"inputinsns",model:{value:(scope.row.standard),callback:function ($$v) {_vm.$set(scope.row, "standard", $$v)},expression:"scope.row.standard"}})],1)]]}}],null,true)})],1)],1)],1)])})],2)])})],2),_c('div',{staticStyle:{"width":"100%","height":"80px"}}),_c('div',{staticClass:"bangding"},[(
        this.registerType == '1' &&
        (this.roleType.indexOf('4') !== -1 ||
          this.roleType.indexOf('3') !== -1) &&
        this.$route.query.type != '1'
      )?_c('el-button',{attrs:{"type":"primary","disabled":_vm.btnDisab},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("提交绑定")]):_vm._e(),(
        this.registerType == '2' &&
        (this.roleType.indexOf('7') !== -1 ||
          this.roleType.indexOf('6') !== -1) &&
        this.$route.query.oridStatus == '0'
      )?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.toSubmit()}}},[_vm._v("确定")]):_vm._e()],1),_c('Diaol',{ref:"diaol"}),_c('Repair',{ref:"repair"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
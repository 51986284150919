//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getcode, _gettree, _getgetLeaders } from "@/api/organizational";
import {_getconfim} from "@/api/maintenance";

export default {
  watch: {
    "form.status"(val) {
      this.swith = val == "0" ? true : false;
      if(val == '0'){
         this.$refs["form"].resetFields();
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      swith: false,
      btnDisab:false,
      form: {
        id:this.id,
        status: 1,
        spUid:"",
        spDid:"",
        failReason: "", //拒绝原因
        planId:"",
      },
      list: [
        { name: "是", id: 1 },
        { name: "否", id: 0},
      ],
      engList: [], //部门数据
      curcityist: [],
    };
  },
  methods: {
    init(id,planId) {
      this.form.planId = planId;
      this.form.id = id;
      this.dialogVisible = true;
      _gettree({ child: false }).then((res) => {
          this.engList = res.data;
      });
    },
    change(val) {
      this.form.spUid = ""
      _getgetLeaders({ deptId: val }).then((res) => {
        this.curcityist = res.data;
      });
    },
    // 确认
    adduser() {
      this.$refs["form"].validate((valid) => {
        if(valid){
          this.btnDisab = true
        this.engList.filter((item)=>{
          if(this.form.spDid == item.id){
            this.form.spDname = item.name
          }
        })
        this.curcityist.filter((item)=>{
          if(this.form.spUid == item.id){
            this.form.spUname = item.name
          }
        })
      _getconfim(this.form).then((res)=>{
        if(res.code == "1"){
          this.dialogVisible = false;
          this.btnDisab = false
          this.$message(res.msg)
          this.$emit("submitben")
          this.$router.push("/main/maintenance/plan");

        }else{
          this.btnDisab = false
        }
      }).catch(()=>{
         this.btnDisab = false
      })
        }
      })
      
    },
    close() {
      this.form = {
        id:this.id,
        status: 1,
        spUid:"",
        spDid:"",
        failReason: "", //拒绝原因
        planId:"",
      };
      this.$refs["form"].resetFields();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//

import UserLists from './UserLists.vue'
import UserAnalysis from './UserAnalysis.vue'
export default {
  components: {
    UserLists,
    UserAnalysis,
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {_staff,_scansuperiordept} from "@/api/check.js";
export default {
  data() {
    return {
      tabData:[],
      departmentData:[],
       formInline: {
         did: "",
        startTime: "",
        endTime:"",
      },
    };
  },
  created(){
    //  this.staff(this.formInline)
     this.scansuperiordept()
  },
  methods: {
       tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:48px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
   staff(from){
      _staff(from).then((res)=>{
        this.tabData = res.data
      })
    },
      scansuperiordept() {
      _scansuperiordept({}).then((res) => {
        this.departmentData = res.data
           //下面新加的
        this.formInline = {...this.formInline , did : this.departmentData[0].id}
        this.staff(this.formInline)
      });
    },
     query() {
      this.staff(this.formInline)
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from "echarts";
import { _deptReport ,_scansuperiordept} from "@/api/check.js";
export default {
  data() {
    return {
      formInline: {
         did: "",
        startTime: "",
        endTime:"",
      },
      departmentData:[],
      time: [],
      legend: [],
      stackedData: [],
    };
  },
  created() {
    // this.deptReport(this.formInline);
    this.scansuperiordept()
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let chartDom = document.getElementById("mysChart");
      let myChart = echarts.init(chartDom);
      myChart.clear()
      // 绘制图表
      myChart.setOption({
        tooltip: {
          //提示性文本消息
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          y: "bottom",
          data: this.legend,
          textStyle: {
            fontSize: 14,
          },
        },
        grid: {
          //图表位置
          top: "3%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: [
          //横坐标
          {
            type: 'category',
            data: this.time,
          },
        ],
        yAxis: [
          {
            // 纵轴标尺固定
            type: "value",
          },
        ],
        series: this.stackedData,
      });
    },
    deptReport(from) {
      this.time = []
      this.legend = []
      this.stackedData = []
      _deptReport(from).then((res) => {
        this.time = res.data.map((item) => {
          //获取X时间数据
          return item.month;
        });
        //聚合下面的文字
        res.data.forEach((item) => {
          this.legend = item.data.map((iovs) => {
            return iovs.dept;
          });
        });
        //聚合stackedData大数组
        res.data.map((item) => {
          this.stackedData = [];
          item.data.forEach((iovs,index) => {
            //数据
            this.stackedData.push({
                name: iovs.dept,
                type: 'bar',
                stack: 'Ad',
                emphasis: {
                  focus: 'series'
                },
              data: [],
            });
          });
        });
        this.stackedData.forEach((item)=>{
          res.data.forEach((iovs)=>{
            iovs.data.forEach((deps)=>{
            if(item.name == deps.dept){
              item.data.push(deps.WorkOrderNum)
            }
            })
          })
        })
        this.drawLine();
      });
    },
        // 获取下拉框部门数据
    scansuperiordept() {
      _scansuperiordept({}).then((res) => {
        this.departmentData = res.data
         //下面新加的
        this.formInline = {...this.formInline , did : this.departmentData[0].id}
        this.deptReport(this.formInline);
      });
    },
    query() {
      this.deptReport(this.formInline)
    },
  },
};

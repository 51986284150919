//
//
//
//
//
//
//

import * as echarts from 'echarts'
export default {
  data() {
    return {
      option: {
        title: {
          text: '客户列表',
          // subtext: 'Fake Data',
          left: 'left',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
          width : 16,
          height : 16,
          borderRadius : 8
        },
         grid: {
          //图表位置
          left: "4%",
          right: "4%",
          height: '70%',
          bottom: "10%",
          containLabel: true,
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['55%', '70%'],
            data: [
              { value: 1048, name: '未结算' },
              { value: 735, name: '已结算' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            markPoint: {
              symbol:
                'image://https://img02.mockplus.cn/idoc/xd/2021-11-03/dd954a35-7095-4e90-b7e4-4dbe63043b1f.png',
              symbolSize: 35,
              data: [
                //跟你的仪表盘的中心位置对应上，颜色可以和画板底色一样
                {
                  x: 'center',
                  y: 'center',
                },
              ],
            },
            label: {
              formatter: '{b|{b}}\n{per|{d}%}\n  {c|￥{c}}    ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              rich: {
                b: {
                  color: '#8F8F8F',
                  fontSize: 16,
                  fontWeight: 'bold',
                  lineHeight: 33,
                  align: 'center'
                },
                per: {
                  color: '#03B4A8',
                  align: 'center',
                  fontSize: 26,
                  lineHeight: 40,
                },
                c :{
                  color: '#03B4A8',
                  fontSize: 16,
                  align: 'center'
                  
                }
              },
            },
            labelLine:{
              length : 0,
              length2 : 160,
               showAbove: false
            }
          },
        ],
      },
    }
  },
  mounted() {
    this.inits()
  },
  methods: {
    inits() {
      let chartDom = document.getElementById('mysChart')
      let myChart = echarts.init(chartDom)
      myChart.setOption(this.option)
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _planList, _getplanInfo ,_getcreateOrder,_setplanDisable,_setplanEndisable} from "@/api/maintenance";
import { _getcode } from "@/api/organizational";
import Repair from "./repair.vue"
export default {
  components: {
    Repair,
  },
  data() {
    return {
      pageSize:10,//没页几条
      current: 1,//当前页数
      total:0,//总条数
      type: false,
      registerType:"",
      roleType:"",
      as: 1,
      formInline: {
        name: "",
        status: "",
      },
      tableData: [],
      list:[]
    };
  },
  created() {
    this.type =
    JSON.parse(localStorage.getItem("registerType")) == "1" ? true : false;
    this.roleType = JSON.parse(localStorage.getItem("roleType")); 
    this.registerType = JSON.parse(localStorage.getItem("registerType"));
      this.planList({current:this.current, page:this.pageSize});
      this.getcode()
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    // 列表数据
    planList(from) {
      _planList(from).then((res) => {
        res.data.data.map((item)=>{
         item.contname =  `${item.currentCount}` + '/' + `${item.count}`
        })
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    //计划状态的下拉数据
    getcode(){
       let from = {
        para: "jhzt",
      };
      _getcode(from).then((res)=>{
         this.list = res.data;
      })
    },
    // 查看
    tabClick(row) {
      console.log(this.registerType == '2',this.roleType.indexOf('6') !== -1,row.statusCode == '1',row.tf =='1',this.registerType == '2' && this.roleType.indexOf('6') !== -1&& row.statusCode == '1' && row.tf =='1')
      this.$router.push({
        path: "/main/maintenance/plan/view",
        query: {
          id: row.id,
        },
      });
    },
    // 生成功单的事件
    successBtn(row) {
      this.$refs.repair.init(row.id)
    },
    // 确定
    confirm(row){
        this.$router.push({
        path: "/main/maintenance/plan/addlanys",
        query: {
          // currentCount: row.currentCount,
          oridStatus: row.statusCode,
          type: "1",
          tf:row.tf,
          id: row.id,
          // status:row.planStatus=='已启用'?'1':"0"
        },
      });
    },
    // 修改的事件
    handle(row) {
      this.$router.push({
        path: "/main/maintenance/plan/addlanys",
        query: {
          // currentCount: row.currentCount,
          oridStatus: row.statusCode,
          type: "2",
          tf:row.tf,
          id: row.id,
          // status:row.planStatus=='已启用'?'1':"0"
        },
      });
    },
    disable(row){
       this.$confirm('是否确定禁用', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _setplanDisable({planId:row.id}).then((res)=>{
            if(res.code == "1"){
              this.$message(res.msg)
              this.planList({current:this.current, page:this.pageSize,...this.formInline});
            }
          })
        })
    },
    // 启用
    endisable(row){
      this.$confirm('是否确定启用', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _setplanEndisable({planId:row.id}).then((res)=>{
            if(res.code == "1"){
              this.$message(res.msg)
              this.planList({current:this.current, page:this.pageSize,...this.formInline});
            }
          })
        })

    },
    // 新增的事件
    add() {
      this.$router.push({
        path: "/main/maintenance/plan/addlanys",
         query: {
          oridStatus: "",
           type: "3",
        },
      });
    },
    // 查询事件
    query() {
        this.planList(this.formInline)
    },
    handleSizeChange(val) {
      this.current = 1
      this.pageSize = val
       let from = {
        current:this.current,
        page:this.pageSize
        }
      this.planList(from)
    },
    handleCurrentChange(val) {
      this.current = val
      let from = {
        current:this.current,
        page:this.pageSize,
        }
      this.planList(from)
    },
  },
};

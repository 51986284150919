//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['tabData'],
  data() {
    return {}
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0'
      }
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Table from "./TableList.vue";
import BarEcharts from './BarEcharts.vue'
import PieEcharts from './PieEcharts.vue'
export default {
  components: {
    Table,
    BarEcharts,
    PieEcharts,
  },
  data(){
    return {
      tabData: [{}, {}, {}, {}],
    }
  }
}

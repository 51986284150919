//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  _setequmentlist,
  _setedepts,
  _settemplateEditInfo,
  _settemplateEdit,
} from "@/api/maintenance";
export default {
  name: "Ordertemplate",
  data() {
    return {
      btnDisab:false,
      activeName: "1",
      activeNames: "1",
      content: "新增维保模板工单",
      // 搜索条件
      name: "",
      // 维保服务第一个数组
      formList: {
        serviceName: "",
        content: "",
        standards: "",
      },
      // 维保服务数组
      serviceLists: [],
      //维保设备第一个数组
      firstList: {
        name: "",
        firm: "",
        price: "",
        mpscId: "",
        type: "",
        serviceStandarder: [],
      },
      //   维保设备的数组
      equipmentList: [],
      options: [],
      disab:false
    };
  },
  created() {
    this.setequmentlist({ type: "1" });
    this.content = this.$route.query.type == "1" ? "修改维保模板工单" : "新增维保模板工单";
    this.disab = this.$route.query.type == "1" ? true :false

    if (this.$route.query.type == "1") {
      _settemplateEditInfo({ id: this.$route.query.id }).then((res) => {
        this.name = res.data.name;
        if (res.data.serviceLists.length > 1) {
          this.formList = res.data.serviceLists[0];
          res.data.serviceLists.splice(0, 1);
          this.serviceLists = res.data.serviceLists;
        } else {
          this.formList = res.data.serviceLists[0];
          this.serviceLists = [];
        }

        if (res.data.content.length > 1) {
          this.firstList = res.data.content[0];
          res.data.content.splice(0, 1);
          this.equipmentList = res.data.content;
        } else {
          this.firstList = res.data.content[0];
          this.equipmentList = [];
        }
      });
    }
  },
  methods: {
    // 返回按钮事件
    goBack() {
      this.$router.push("/main/maintenance/mode");
    },
    // 确认事件
    onSubmit() {
      if (this.$route.query.type == "1") {
        //修改
        let from = {
          name: this.name,
          serviceLists: this.serviceLists.concat([this.formList]),
          content: this.equipmentList.concat([this.firstList]),
          id: this.$route.query.id,
        };
        this.btnDisab = true
        _settemplateEdit(from).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.btnDisab = false
            this.$router.push("/main/maintenance/mode");
          }else{
            this.btnDisab = false
          }
        }).catch(()=>{
          this.btnDisab = false
        });
      } else {
        let from = {
          name: this.name,
          serviceLists: this.serviceLists.concat([this.formList]),
          content: this.equipmentList.concat([this.firstList]),
        };
        _setedepts(from).then((res) => {
          if (res.code == 1) {
            this.btnDisab = false
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.push("/main/maintenance/mode");
          }else{
            this.btnDisab = false
          }
        }).catch(()=>{
          this.btnDisab = false
        });
      }
    },
    // 新增维保服务按钮
    addservice() {
      this.serviceLists.push({
        serviceName: "",
        content: "",
        standards: "",
      });
    },
    // 新增维保设备按钮
    addequipment() {
      this.equipmentList.push({
        name: "",
        firm: "",
        price: "",
        mpscId: "",
        type: "",
        serviceStandarder: [],
      });
    },
    // 第一个维保的新增内容按钮事件
    addlist() {
      this.firstList.serviceStandarder.push({});
    },
    //新增下的新增
    adddatalist(index) {
      this.equipmentList[index].serviceStandarder.push({});
    },
    // 移除事件
    remove(index) {
      this.serviceLists.splice(index, 1);
    },
    removeOneList(index) {
      this.firstList.serviceStandarder.splice(index, 1);
    },
    // 移除事件
    removeequ(index) {
      this.equipmentList.splice(index, 1);
    },
    // 获取下拉数据的接口
    setequmentlist(from) {
      _setequmentlist(from).then((res) => {
        this.options = res.data;
      });
    },
    // 第一个下拉框的change事件
    changeopt(e) {
      let bbb = JSON.parse(JSON.stringify(this.options));
      let aaa = bbb.filter((item) => {
        return item.id == e;
      });
      this.firstList.serviceStandarder = aaa[0].content;
    },
    // 移除维保设备每个的内容和标准事件
    removedataList(index, indexs) {
      this.equipmentList[index].serviceStandarder.splice(indexs, 1);
    },
    // ceange事件下新增的所有的下拉列表通过index找对应数组
    changeequip(e, index) {
      let bbb = JSON.parse(JSON.stringify(this.options));
      let aaa = bbb.filter((item) => {
        return item.id == e;
      });
      this.equipmentList[index].serviceStandarder = aaa[0].content;
    },
  },
};

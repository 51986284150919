//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Table from "./TableList.vue";
import BarEcharts from "./BarEcharts.vue";
import OnlyPieEcharts from "./OnlyPieEcharts.vue";
import FirstPieEcharts from "./FirstPieEcharts.vue";
import SecondPieEcharts from "./SecondPieEcharts.vue";
import ThirdPieEcharts from "./ThirdPieEcharts.vue";
import ForthPieEcharts from "./ForthPieEcharts.vue";
import { _analysis, _freeEquipmentSP ,_serviceprovider} from "@/api/check.js";

export default {
  components: {
    Table,
    BarEcharts,
    OnlyPieEcharts,
    FirstPieEcharts,
    SecondPieEcharts,
    ThirdPieEcharts,
    ForthPieEcharts,
  },
  data() {
    return {
      tabData:[],//表格数据
      departmentData: [],//下拉数据
      formInline: {
        spDid: "",
        startTime: "",
        endTime: "",
      },
      barXData:[],
      barServeData:[],
      orderNum:[],
      answerTimes:[],
      grabTime:[],
      workOrder:[],
      apply:[],   
       };
  },
  created() {
    this.freeEquipmentSP();
    this.serviceprovider();//表格数据
  },
  methods: {
    freeEquipmentSP() {
      _freeEquipmentSP({}).then((res) => {
        this.departmentData = res.data;
        this.departmentData.forEach((item,index)=>{
         if(index == '0'){
           this.formInline.spDid = item.spDid
         }
        })
     this.analysis(this.formInline);
      });
    },
    // 查询
    query() {
      this.analysis(this.formInline);
    },
    //表格数据
    serviceprovider(){
      _serviceprovider({}).then((res)=>{
        this.tabData = res.data
      })
    },
    //数据
    analysis(from) {
      _analysis(from).then((res) => {
        this.barServeData = []
        this.barXData = []
        this.barXData =  res.data.order.map((item)=>{
          return item.serviceName
        })
        res.data.order.map((item)=>{
          this.barServeData = []
            item.data.forEach((iovs)=>{
              this.barServeData.push(
              {
                name: iovs.name,
                type: 'bar',
                barMaxWidth: 20,
                data: [],
                itemStyle : {
                  borderRadius : [50,50,0,0]
                }
          })
            })
        })
        this.barServeData.forEach((item)=>{
          res.data.order.forEach((iovs)=>{
            iovs.data.forEach((desp)=>{
              if(item.name == desp.name){
              item.data.push(desp.value)
            }
            })
          })
        })
        this.orderNum = res.data.orderNum
        this.answerTimes = res.data.answerTimes.data
        this.grabTime = res.data.grabTime.data
        this.workOrder = res.data.workOrder.data
        this.apply = res.data.Apply
      });

    },
  },
};

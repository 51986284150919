//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _gettree } from '@/api/organizational.js'

import {
  _getRespLeader,
  _getdatelist,
  _setequmentlist,
  _getmake,
  _getplanInfo,
  _getfreeEquipmentSP,
  _getdemandDept,
  _getcreateOrder,
  _setplanEdit,
  _setalldept
} from '@/api/maintenance'

import { _getBindInfo, _getselectUserBindDeptList } from '@/api/disabilities.js'
import Diaol from './diaol.vue'
import Repair from './repair.vue'
import TimeLine from '../../../../components/timeLine.vue'
import Viewtimeline from '../../../../components/viewtimeline.vue'
export default {
  components: {
    Diaol,
    Repair,
    TimeLine,
    Viewtimeline,
  },
  computed: {
    priceParam: function() {
      return (
        this.formsdes.startTime,
        this.formsdes.endTime,
        this.formsdes.period,
        this.formsdes.count
      )
    },
  },
  watch: {
    priceParam: {
      //深度监听，可监听到对象、数组的变化
      handler() {
        console.log(11111111)
        // 1996  1998
        if (
          this.formsdes.startTime != '' &&
          this.formsdes.endTime != '' &&
          this.formsdes.count != '' &&
          this.initStr == '1998'
        ) {
          this.$refs.timeline.init(
            this.formsdes.startTime,
            this.formsdes.endTime,
            Number(this.formsdes.count),
            this.viewBtn,
          )
        }
        if (this.myDate.length != 0 && this.initStr == '1996') {
          this.$refs.timeline.draw_listen(
            this.myDate,
            this.coordinate,
            this.formsdes.startTime,
            this.formsdes.endTime,
            '1',
          )
          this.initStr = '1998'
        }
        if (
          this.myDate.length == 0 &&
          this.formsdes.startTime != '' &&
          this.formsdes.endTime != '' &&
          this.formsdes.count != '' &&
          this.initStr == '1996'
        ) {
          //新增
          this.$refs.timeline.init(
            this.formsdes.startTime,
            this.formsdes.endTime,
            Number(this.formsdes.count),
            this.viewBtn,
          )
        }
      },
      deep: true, //true 深度监听
    },
  },
  data() {
    // 开始时间
    return {
      // 结束日期不能大于开始日期
      pickerOptions: {
        disabledDate: time => {
          if (this.formsdes.startTime) {
            return (
              time.getTime() <
              new Date(this.formsdes.startTime).getTime() - 86400000
            )
          }
          return time.getTime() < new Date().getTime() - 86400000
        },
      },
      // 开始日期大于结束日期
      newPickerOptions: {
        disabledDate: time => {
          if (this.formsdes.endTime) {
            return (
              time.getTime() > new Date(this.formsdes.endTime).getTime() ||
              time.getTime() <= new Date().getTime() - 86400000
            )
          }
          return time.getTime() < new Date().getTime() - 86400000
        },
      },
      // 时间线的数据
      timeLineData: {
        stime: '',
        etime: '',
        cnum: '',
      },
      initStr: '1996',
      coordinate: [], //坐标
      formsdes: {
        name: '',
        startTime: '',
        endTime: '',
        period: '',
        count: '',
        respUid: '',
      },
      MapTime: [],
      repairoptions: [],
      timeLengtn: '', //时间线的map对象长度
      gitSwith: false, //修改时间控制名称和开始时间
      value: 100, //时间线滑动的
      switem: false, //控制次数的出现
      TimeShow: false,
      headlist: [], //需求方负责人下拉数据
      newMarks: [], //维保时间线框
      dateLists: {}, //维保时间线的数据
      timtXydata: [], //维保时间线的坐标
      myDate: [], //维保时间线的时间数据
      curcityist: [], //部门列表
      templateoptions: [], // 维保设备模板下拉数据
      freeEquipmentSP: [], //游离设备的下拉数据
      registerType: '', //登录类型 1需求方 2服务商
      roleType: '', //角色类型
      viewBtn: false, //区分查看进来是禁用还是什么
      // 网点维保数组
      contentList: [],
      // 游离设备的数据
      freeEquipments: [],
      btnDisab: false,
      absouled: '0',
    }
  },
  created() {
    this.getRespLeader() // 需求方负责人下拉数据
    this.setequmentlist() //设备模板的下拉数据
    this.getfreeEquipmentSP() //游离设备的服务商下拉数据
    this.registerType = JSON.parse(localStorage.getItem('registerType')) //获取用户登录类型来判断提交事件
    if (this.registerType == '1') {
      this.getcur() //部门下拉列表
    } else {
      _getdemandDept({}).then(res => {
        this.curcityist = res.data
      })
    }
    this.roleType = JSON.parse(localStorage.getItem('roleType')) //获取用户角色类型
    if (this.$route.query.type == '1') {
      //点击查看进来
      this.getplanInfo({ id: this.$route.query.id }) //调取详情接口
      this.viewBtn = true
    } else if (this.$route.query.type == '2') {
      //修改进来
      this.getplanInfo({ id: this.$route.query.id }) //调取详情接口
      this.viewBtn = false
      this.gitSwith = true
    } else {
      //新增进来
      this.viewBtn = false
    }
  },
  methods: {
    // 接受子组件传参时间
    timedatas(val) {
      this.dateLists = val
    },
    // 接受子组件传参坐标
    myBls(val) {
      this.timtXydata = val
    },
    //tab表头样式
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:30px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;'
      }
    },
    // 获取详情数据
    getplanInfo(row) {
      _getplanInfo(row).then(res => {
        if (res.code == '1') {
          console.log(res.data)
          this.formsdes = Object.assign(res.data)
          this.formsdes.dateLists.forEach(item => {
            this.coordinate.push(item.coordinate) //时间轴坐标数据
            this.myDate.push(item.startTime) //时间轴时间数据
          })
          this.formsdes.endTime = `${res.data.endTime}` + 'T00:00:00.000Z'
          //修改
          if (this.$route.query.type == '2') {
            let d = new Date()
            let s =
              d.getFullYear() +
              '-' +
              (d.getMonth() + 1 > 9
                ? d.getMonth() + 1
                : '0' + (d.getMonth() + 1)) +
              '-' +
              (d.getDate()> 9
                ? d.getDate()
                : '0' + d.getDate())
            console.log(this.formsdes.startTime, s, 222)
            this.formsdes.startTime = `${s}` + 'T00:00:00.000Z'
            console.log(this.myDate, this.coordinate, 'AAAAAAAAA')
          } else {
            //查看
            this.formsdes.startTime = `${res.data.startTime}` + 'T00:00:00.000Z'
            // 开始时间  结束时间  计划期数  新增还是修改查看  时间数组  坐标数组
            this.$refs.viewtimeline.init(
              this.formsdes.startTime,
              this.formsdes.endTime,
              this.myDate,
              this.coordinate,
            )
          }
          if (this.formsdes.period == '3') {
            this.switem = true
          } else {
            this.switem = false
          }
          res.data.contentList.forEach(item => {
            _getBindInfo({ did: item.deptId }).then(iove => {
              item.uesrlistsnew = iove.data
              // let sss = iove.data.map((x)=>{
              //   return x.spDid
              // })
              // if(iove.data.length == 0){
              //   item.spDid = ""
              // }else{
              //     if(item.spDid.indexOf(sss) == -1){
              //        item.spDid = ""
              // }
              // }
              this.$forceUpdate()
            })
            var datas = []
            item.serviceList.forEach(oneitem => {
              datas.push(oneitem)
            })
            item.serviceList = [{ datas: datas }]
          })
          this.contentList = res.data.contentList
          // res.data.freeEquipments.forEach((item)=>{
          // })
          this.freeEquipments = res.data.freeEquipments
        }
      })
    },
    // 获取游离设备的服务商数据
    getfreeEquipmentSP() {
      _getfreeEquipmentSP({}).then(res => {
        this.freeEquipmentSP = res.data
      })
    },
    // 返回按钮事件
    goBack() {
      this.$router.push('/main/maintenance/plan')
    },
    // 维保工单模板切换事件
    changetelmpt(e, index) {
      if (e) {
        let repairoptions = JSON.parse(JSON.stringify(this.repairoptions))
        let Arr = repairoptions.filter(item => {
          return item.id == e
        })
        let serveData = Arr[0].content
        serveData.forEach(item => {
          item.content = item.contentName
          item.standards = item.standard
        })
        this.contentList[index].serviceList = [{ datas: serveData }]
      }
    },
    // 需求方负责人的下拉列表
    getRespLeader() {
      _getRespLeader({}).then(res => {
        this.headlist = res.data
      })
    },
    // 获取部门下拉框
    getcur() {
      _setalldept({}).then(res => {
        // console.log(res)
        this.curcityist = res.data
      })
      // child: true
      // _gettree({child: true }).then((res) => {
      //   if (res.data.child.length != 0) {

      //     this.curcityist = [...res.data.child,res.data.parent];
      //   } else {
      //     this.curcityist = [res.data.parent];
      //   }
      // });
    },
    // 获取设备模板和工单模板下拉数据
    setequmentlist() {
      _setequmentlist({ type: '1' }).then(res => {
        this.templateoptions = res.data
        this.repairoptions = res.data
      })
    },

    //获取服务商下拉框
    changeServe(index, val) {
      this.contentList[index].spDid = ''
      this.curcityist.filter(item => {
        if (val == item.id) {
          this.contentList[index].deptName = item.name
        }
      })
      _getBindInfo({ did: val }).then(res => {
        this.contentList[index].uesrlistsnew = res.data
      })
    },
    // 根据id获取name
    changeNewServe(index, val) {
      this.contentList[index].uesrlistsnew.filter(item => {
        if (val == item.spDid) {
          this.contentList[index].spName = item.spDname
        }
      })
    },
    // 根据id获取name
    changeNew(index, val) {
      this.freeEquipmentSP.filter(item => {
        if (val == item.spDid) {
          this.freeEquipments[index].spDname = item.spDname
        }
      })
    },
    // 新增网点维保
    addBranches() {
      this.contentList.unshift({
        deptName: '', //部门名称
        deptId: '', //部门id
        spDid: '', //服务商id
        spName: '', //服务商名称
        // 服务商下拉框
        uesrlistsnew: [],
        serviceList: [
          {
            datas: [
              {
                serviceName: '',
                content: '',
                standards: '',
              },
            ],
          },
        ],
        content: [],
      })
    },
    // 删除
    delBranches(index) {
      this.contentList.splice(index, 1)
    },
    // // 新增维保网点
    // addServe(index) {
    //   this.contentList[index].serviceList[0].datas.unshift({
    //     serviceName: "",
    //     content: "",
    //     standards: "",
    //   });
    // },
    // 删除
    // click_delete(index, indexs) {
    //   this.contentList[index].serviceList[0].datas.splice(indexs, 1);
    // },
    // 新增维保内容
    addcontent(index) {
      this.contentList[index].content.unshift({
        name: '',
        firm: '',
        price: '',
        templateId: '',
        type: '',
        serviceStandarder: [
          {
            contentName: '',
            standard: '',
          },
        ],
      })
    },
    // 移除维保内容
    delContent(index, indexs) {
      this.contentList[index].content.splice(indexs, 1)
    },
    // 维保内容里的服务名称
    addName(index, indexs) {
      this.contentList[index].content[indexs].serviceStandarder.unshift({
        contentName: '',
        standard: '',
      })
    },
    // 删除维保内容里的服务名称
    oneclick_delete(index, indexs, indexss) {
      this.contentList[index].content[indexs].serviceStandarder.splice(
        indexss,
        1,
      )
    },
    // 网点维保的新增
    imgAdd() {
      this.freeEquipments.unshift({
        dDname: '', // 部门名称
        dDid: '', //部门id
        spDname: '', //服务商名称
        spDid: '', //服务商id
        equipments: [
          {
            name: '', //设备名称
            firm: '', //厂商
            eNumber: '', //数量
            ePrice: '', //单价
            eModel: '', //型号
            templateId: '', //模板id
            templateArr: [], //设备模板的数据
            serviceStandarder: [
              {
                contentName: '', //维保内容
                standard: '', //维保完成标准
              },
            ],
          },
        ],
      })
    },
    // 删除游离设备
    imgDel(index) {
      this.freeEquipments.splice(index, 1)
    },
    // 网点维保内容新增
    maintenanceAdd(index) {
      this.freeEquipments[index].equipments.unshift({
        name: '', //设备名称
        firm: '', //厂商
        eNumber: '', //数量
        ePrice: '', //单价
        eModel: '', //型号
        templateId: '', //模板id
        templateArr: [], //设备模板的数据
        serviceStandarder: [
          {
            contentName: '', //维保内容
            standard: '', //维保完成标准
          },
        ],
      })
    },
    // 维保网点的删除
    maintenanceDel(index, indexs) {
      this.freeEquipments[index].equipments.splice(indexs, 1)
    },
    // 维保网点表格下的的新增
    tabAdd(index, indexs) {
      this.freeEquipments[index].equipments[indexs].serviceStandarder.unshift({
        contentName: '', //维保内容
        standard: '', //维保完成标准
      })
    },
    // 维保网点表格下的的删除
    tabDel(index, indexs, indexss) {
      this.freeEquipments[index].equipments[indexs].serviceStandarder.splice(
        indexss,
        1,
      )
    },

    // 网点设备里的设备模板的change 赋值事件
    changeequip(e, index, indexs) {
      let templateoptions = JSON.parse(JSON.stringify(this.templateoptions))
      let Arr = templateoptions.filter(item => {
        return item.id == e
      })
      this.contentList[index].content[indexs].serviceStandarder = Arr[0].content
    },
    // 游离设备里的设备模板的change 赋值事件
    changtempl(e, index, indexs) {
      let templateoptions = JSON.parse(JSON.stringify(this.templateoptions))
      let Arr = templateoptions.filter(item => {
        return item.id == e
      })
      this.freeEquipments[index].equipments[indexs].serviceStandarder =
        Arr[0].content
    },

    // 提交绑定的确认事件
    onSubmit() {
      this.btnDisab = true
      let allValidate = []
      this.contentList.forEach((item, index) => {
        allValidate.push(
          new Promise((rev, rej) => {
            this.$refs['item' + index][0].validate(valid => {
              if (valid) {
                rev()
              } else {
                rej()
              }
            })
          }),
        )
      })

      this.contentList.forEach((item, index) => {
        item.serviceList[0].datas.forEach(iovs => {
          allValidate.push(
            new Promise((rev, rej) => {
              this.$refs['items' + index][0].validate(valid => {
                if (valid) {
                  rev()
                } else {
                  rej()
                }
              })
            }),
          )
        })
      })
      this.contentList.forEach(item => {
        item.content.forEach((oneitem, indexs) => {
          allValidate.push(
            new Promise((rev, rej) => {
              this.$refs['oneitem' + indexs][0].validate(valid => {
                if (valid) {
                  rev()
                } else {
                  rej()
                }
              })
            }),
          )
        })
      })

      this.contentList.forEach(item => {
        item.content.forEach((oneitem, indexs) => {
          allValidate.push(
            new Promise((rev, rej) => {
              this.$refs['oneitemss' + indexs][0].validate(valid => {
                if (valid) {
                  rev()
                } else {
                  rej()
                }
              })
            }),
          )
        })
      })
      this.freeEquipments.forEach((item, index) => {
        allValidate.push(
          new Promise((rev, rej) => {
            this.$refs['itemasas' + index][0].validate(valid => {
              if (valid) {
                rev()
              } else {
                rej()
              }
            })
          }),
        )
      })

      this.freeEquipments.forEach(item => {
        item.equipments.forEach((iovs, index) => {
          allValidate.push(
            new Promise((rev, rej) => {
              this.$refs['itemsb' + index][0].validate(valid => {
                if (valid) {
                  rev()
                } else {
                  rej()
                }
              })
            }),
          )
        })
      })

      this.freeEquipments.forEach(item => {
        item.equipments.forEach((iovs, index) => {
          allValidate.push(
            new Promise((rev, rej) => {
              this.$refs['oneitemsa' + index][0].validate(valid => {
                if (valid) {
                  rev()
                } else {
                  rej()
                }
              })
            }),
          )
        })
      })

      allValidate.push(
        new Promise((rev, rej) => {
          this.$refs['dynamicValidateForm'].validate(valid => {
            if (valid) {
              rev()
            } else {
              rej()
            }
          })
        }),
      )
      Promise.all(allValidate)
        .then(() => {
          //所有输入框都校验成功后的处理
          for (var key in this.dateLists) {
            this.MapTime.push(this.dateLists[key])
          }
          let newTimeData = []
          this.MapTime.forEach((item, index) => {
            console.log(this.timtXydata[index])
            newTimeData.push({
              startTime: `${item}` + 'T00:00:00.000Z',
              coordinate: this.timtXydata[index],
            })
          })
          let contList = JSON.parse(JSON.stringify(this.contentList))
          contList.forEach(item => {
            let aaa = []
            item.serviceList.forEach(oneitem => {
              aaa.push(...oneitem.datas)
              item.serviceList = aaa
            })
          })
          let from = {
            ...this.formsdes,
            dateLists: newTimeData,
            contentList: contList,
            freeEquipments: this.freeEquipments,
          }
          console.log(from, 'AAAAAAAAAAAAA')
          if (this.$route.query.type == '3') {
            //新增
            _getmake(from)
              .then(res => {
                if (res.code == '1') {
                  this.btnDisab = false
                  this.$message(res.msg)
                  this.$router.push('/main/maintenance/plan')
                  this.TimeShow = false
                } else {
                  this.btnDisab = false
                }
              })
              .catch(() => {
                this.btnDisab = false
              })
          } else if (this.$route.query.type == '2') {
            //修改
            _setplanEdit(from)
              .then(res => {
                if (res.code == '1') {
                  this.btnDisab = false
                  this.$message(res.msg)
                  this.$router.push('/main/maintenance/plan')
                  this.TimeShow = false
                } else {
                  this.btnDisab = false
                }
              })
              .catch(() => {
                this.btnDisab = false
              })
          }
        })
        .catch(() => {
          this.btnDisab = false
          this.$message({
            message: '有部分内容为空，请完善维保计划信息',
            type: 'warning',
          })
        })
    },
    // 确定
    toSubmit() {
      //服务商确定
      let ids = ''
      if (this.contentList[0]) {
        ids = this.contentList[0].id
      } else {
        ids = this.freeEquipments[0].id
      }
      this.$refs.diaol.init(ids, this.$route.query.id)
    },
    // 生成功单的事件
    successBtn() {
      this.$refs.repair.init(this.$route.query.id)
    },
  },
}

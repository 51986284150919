//
//
//
//
//
//

import * as echarts from 'echarts'
export default {
  data() {
    return {
      option: {
        // title: {
        //   subtext: '服务项目统计',
        //   left: 'center',
        // },
         tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['55%', '70%'],
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            markPoint: {
              symbol:
                'image://https://img02.mockplus.cn/idoc/xd/2021-11-03/27822d78-0502-4fb4-bdb6-06d2ceea8801.png',
              symbolSize: 35,
              data: [
                //跟你的仪表盘的中心位置对应上，颜色可以和画板底色一样
                {
                  x: 'center',
                  y: 'center',
                },
              ],
            },
          },
        ],
      },
    }
  },
  mounted() {
    this.inits()
  },
  methods: {
    inits() {
      let chartDom = document.getElementById('mysChartPieA')
      let myChart = echarts.init(chartDom)
      myChart.setOption(this.option)
    },
  },
}

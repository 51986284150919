//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from 'echarts'
export default {
  props:["grabTime"],
  watch:{
    "grabTime"(val){
      this.data = val
      this.inits()
    }
  },
  data() {
    return {
      data:[],
    }
  },
  mounted() {
  },
  methods: {
    inits() {
      let chartDom = document.getElementById('mysChartPieSecond')
      let myChart = echarts.init(chartDom)
      myChart.clear()
      myChart.setOption({
        title: {
          subtext: '服务工程师响应时长',
           left: 'center',
          subtextStyle : {
            fontSize : 16
          }
        },
         tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: 'bottom',
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '65%'],
            data: this.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            markPoint: {
              symbol:
                'image://https://img02.mockplus.cn/idoc/xd/2021-11-03/27822d78-0502-4fb4-bdb6-06d2ceea8801.png',
              symbolSize: 35,
              data: [
                //跟你的仪表盘的中心位置对应上，颜色可以和画板底色一样
                {
                  x: 'center',
                  y: 'center',
                },
              ],
            },
            label : {
              show : false
            }
          },
        ],
      },)
    },
  },
}

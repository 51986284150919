//
//
//
//

import * as echarts from 'echarts'
import {_analysis} from "@/api/check.js";
export default {
  props:["barXData","barServeData"],
  data() {
    return {
      data:[],
      serveData:[],
    }
  },
  watch:{
    "barXData"(val){
      this.data = val
    },
    "barServeData"(val){
      this.serveData = val
      this.inits()
    }
  },
  methods: {
    inits() {
      let chartDom = document.getElementById('mysChartBar')
      let myChart = echarts.init(chartDom)
      myChart.clear()
      myChart.setOption({
        title: {
          text: '部门工单统计',
          // subtext: 'Fake Data',
          left: 'left',
        },
         tooltip: {
          //提示性文本消息
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          type: 'plain',
          bottom: 'bottom',
        },
        xAxis: {
          type: 'category',
          data:this.data,
        },
        yAxis: {
          type: 'value',
        },
         grid: {
          //图表位置
          left: '4%',
          right: '4%',
          height: '70%',
          bottom: '10%',
          containLabel: true,
        },
        series: this.barServeData,
      })
    },
  },
}

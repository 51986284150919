//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BrokenLine from "./brokenLine.vue";
import StackedBar from "./stackedBar.vue";
import Table from "./table.vue";
import Tables from "./tables.vue"
import {_count}  from "@/api/check.js";
// import Distribution from "./distribution.vue";
// Distribution,
export default {
  components: {
    BrokenLine,
    StackedBar,
    Table,
    Tables,
  },
  data() {
    return {
      ArrData:{
        maintenanceNum:[{
          approval: "",
          checks: "",
          complete: "",
          confrim: "",
          distribute: "",
          implentment: "",
          implentmenting: "",
          notFinish: "",
          reject: "",
          takeOrder: "",
        }],
        reportNum:[{
          approval: "",
          checks: "",
          complete: "",
          confrim: "",
          distribute: "",
          gdsc: "",
          implentment: "",
          implentmenting: "",
          notFinish: "",
          reject: "",
          takeOrder: "",
        }]
      },
      types:""
    };
  },
  created(){
    this.count()
    this.types = JSON.parse(localStorage.getItem("registerType"));

  },
  methods: {
    count(){
      _count({}).then((res)=>{
        this.ArrData = res.data
      })
    }
  },
};

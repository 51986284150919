//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from 'echarts'
export default {
  data() {
    return {
      option: {
        // title: {
        //   text: '部门工单统计',
        //   // subtext: 'Fake Data',
        //   left: 'left',
        // },
        legend: {
          type: 'plain',
          bottom: 'bottom',
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
         grid: {
          //图表位置
          left: '4%',
          right: '4%',
          height: '70%',
          bottom: '10%',
          containLabel: true,
        },
        series: [
          {
            name: 'Forest',
            type: 'bar',
            barGap: '5%',
            barMaxWidth: 20,
            data: [320, 332, 301, 334, 390, 334, 390],
            itemStyle : {
              borderRadius : [50 ,50 , 0 ,0]
            }
          },
          {
            name: 'Steppe',
            type: 'bar',
            barMaxWidth: 20,
            data: [220, 182, 191, 234, 290, 334, 390],
            itemStyle : {
              borderRadius : [50 ,50 , 0 ,0]
            }
          },
        ],
      },
    }
  },
  mounted() {
    this.inits()
  },
  methods: {
    inits() {
      let chartDom = document.getElementById('mysChartBar')
      let myChart = echarts.init(chartDom)
      myChart.setOption(this.option)
    },
  },
}

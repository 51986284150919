//
//
//
//
//
//

import * as echarts from 'echarts'
export default {
  data() {
    return {
      option: {
        title: {
          text: '客户地域占比分析',
          // subtext: 'Fake Data',
          left: 'left',
        },
         grid: {
          //图表位置
          left: "4%",
          right: "4%",
          height : '70%',
          bottom: "10%",
          containLabel: true,
        },
        // color: ['#80FFA5', '#00DDFF'],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          
        },
        yAxis: {
          type: 'value',
          // boundaryGap: [0, 0],
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
        },
        series: [
          {
            name: 'Line 1',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              // width: 0
              color: '#5470C6',
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(128, 255, 165)',
                },
                {
                  offset: 1,
                  color: 'rgba(1, 191, 236)',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: [140, 232, 101, 264, 90, 340, 250],
          },
          {
            name: 'Line 2',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              // width: 0
              color: '#5470C6',
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(253,154,1,0.76)',
                },
                {
                  offset: 1,
                  color: 'rgba(253,154,1,0.21)',
                 
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: [120, 282, 111, 234, 220, 340, 310],
          },
        ],
      },
    }
  },
  mounted() {
    this.inits()
  },
  methods: {
    inits() {
      let chartDom = document.getElementById('mysCharts')
      let myChart = echarts.init(chartDom)
      myChart.setOption(this.option)
    },
  },
}

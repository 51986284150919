//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TimeLine',
  data() {
    return {
      disabShow: false,
      viewBtn: '',
      timedatas: [],
      newday: '',
      values: '',
      myBls: [],
      myCirclews: [],
      x: '',
      y: '',
      sdata: '',
      edata: '',
      cnum: 0,
      widths: 2000,
    }
  },
  created() {
    this.widths = document.body.clientWidth - 500
  },
  methods: {
    // query(){
    //   this.$refs.reference.doClose();
    // },
    change(val) {
      for (var m = 0; m < this.myCirclews.length; m++) {
        let dis = Math.sqrt(
          (this.x - this.myCirclews[m]) * (this.x - this.myCirclews[m]) +
            (this.y - 50) * (this.y - 50),
        ) //Math.sqrt()求平方跟
        if (dis <= 5) {
          if (val != null && val != '') {
            console.log(val, this.sdata, this.edata, 'AAAAAAAAAA')
            let time1 = new Date(Date.parse(val))
            let time2 = new Date(Date.parse(this.sdata))
            let time3 = new Date(Date.parse(this.edata))
            if (time1 > time2 && time1 < time3) {
              this.$refs.reference.doClose()
              this.makeNewTime(
                this.timedatas,
                this.myBls,
                m,
                val,
                this.sdata,
                this.edata,
                this.ddiff,
              )
              break
            } else {
              this.$message('输入的时间不在计划范围内')
              this.$refs.reference.doClose()
              break
            }
          }
        }
      }
      this.$refs.reference.doClose()
    },
    init(startdate, enddate, countnum, viewBtn) {
      this.$refs.reference.doClose()
      this.sdata = startdate
      this.edata = enddate
      this.cnum = countnum
      this.viewBtn = viewBtn
      this.disabShow = true
      //期数必须为数值
      if (countnum == '' || countnum <= 0) {
        this.$message('期数必须为数值')
      }
      //判断开始时间是否为空
      if (startdate == '') {
        this.$message('请输入开始时间')
      }
      //判断结束时间是否为空
      if (enddate == '') {
        this.$message('请输入结束时间')
      }
      var diff = parseInt(this.timeDiff(enddate, startdate)) //次数不能多余总长度
      if (diff < countnum + 1) {
        this.$message('次数多于总共时长')
      }
      this.ddiff = diff
      this.makeTime(countnum, startdate, diff, enddate)
    },
    makeTime(countnum, startdate, diff, enddate) {
      let myDate = []
      let myBl = []
      // 次数除以
      let dix = Math.floor(diff / (countnum + 1))
      for (var i = 0; i < countnum; i++) {
        var resultdate = this.addDate(startdate, (i + 1) * dix)
        var bltemp = parseInt(this.timeDiff(resultdate, startdate))
        myDate.push(resultdate)
        myBl.push(bltemp / diff)
      }
      this.timedatas = myDate
      this.myBls = myBl
      // 进行画布的绘制
      this.draw_listen(myDate, myBl, startdate, enddate, diff)
    },
    addDate(date, days) {
      var date = new Date(date)
      date.setDate(date.getDate() + days)
      var month = date.getMonth() + 1
      var day = date.getDate()
      return (
        date.getFullYear() +
        '-' +
        this.getFormatDate(month) +
        '-' +
        this.getFormatDate(day)
      )
    },
    // 日期月份/天的显示，如果是1位数，则在前面加上'0'
    getFormatDate(arg) {
      if (arg == undefined || arg == '') {
        return ''
      }
      var re = arg + ''
      if (re.length < 2) {
        re = '0' + re
      }
      return re
    },
    draw_listen(myDate, myBl, startdate, enddate, diff) {
      if (diff == '1') {
        this.disabShow = true
        diff = parseInt(this.timeDiff(enddate, startdate))
        this.sdata = startdate
        this.edata = enddate
        this.timedatas = myDate
        this.myBls = myBl
        this.ddiff = diff
      }
      let that = this
      var c = document.getElementById('myCanvas') ///获取画布id为myCanvas
      c.height = c.height
      c.width = this.widths
      var ctx = c.getContext('2d') //获取绘图2D环境
      var myCirclew = []
      for (var i = 0; i < myDate.length; i++) {
        var w = 75 + (this.widths - 150) * myBl[i]
        var h = 50
        ctx.strokeStyle = '#2B90FA' //线段颜色
        ctx.lineWidth = 3
        ctx.arc(w, h, 5, 0, 2 * Math.PI, true) //创建弧/曲线（用于创建圆形或部分圆）
        ctx.fillStyle = '#2B90FA'
        ctx.fill()
        ctx.stroke()
        ctx.font = '12px sans-serif'
        ctx.fillStyle = '#000000'
        if (i % 2 == 0) {
          ctx.fillText(myDate[i], w, 75)
        } else {
          ctx.fillText(myDate[i], w, 30)
        }
        myCirclew.push(w)
      }
      this.myCirclews = myCirclew
      ctx.beginPath()
      ctx.moveTo(75, 50)
      ctx.lineTo(that.widths - 75, 50)
      ctx.stroke()
      c.onclick = function(e) {
        //给canvas添加点击事件
        e = e || event //获取事件对象
        that.x = e.clientX - c.offsetLeft
        that.y = e.clientY - c.offsetTop
        for (var m = 0; m < myCirclew.length; m++) {
          var dis = Math.sqrt(
            (that.x - myCirclew[m]) * (that.x - myCirclew[m]) +
              (that.y - 50) * (that.y - 50),
          ) //Math.sqrt()求平方跟
          if (dis <= 5) {
            if (!that.viewBtn) {
              console.log(myCirclew[m]);
              var packerDiv = document.getElementById('packer')
              console.log(window.innerWidth*0.08)
              //  window.innerWidth*0.08 - 13 去掉rem之后使用  但是由于canvas使用的是屏幕宽度减500 所以后续如果有需求  需要更改
              packerDiv.style.left = 130 + myCirclew[m] + 'px'
              that.$refs.reference.doShow()
            }
            if (that.timedatas.length != 0) {
              that.newday = that.timedatas[m]
            } else {
              that.newday = myDate[m]
            }
          }
        }
      }
      that.$emit('timedatas', this.timedatas)
      that.$emit('myBls', this.myBls)
    },
    // 修改后重新绘制时间轴
    makeNewTime(myDate, myBl, m, newday, startdate, enddate, diff) {
      
      myDate[m] = newday
      var newtemp = parseInt(this.timeDiff(newday, startdate))
      myBl[m] = newtemp / diff
      myBl.sort()
      myDate.sort(function(a, b) {
        return b < a ? 1 : -1
      })
      this.draw_listen(myDate, myBl, startdate, enddate, diff)
      
    },
    //   转换时间的方法
    timeDiff(t, t1) {
      // t = t.replace(/-/g,':').replace(' ',':');
      // t1 = t1.replace(/-/g,':').replace(' ',':');
      // 2022-01-27T00:00:00.000Z
      // 2022-1-18T00:00:00.000Z
      var sj = new Date(t)
      var sj1 = new Date(t1)
      var y = sj.getFullYear()
      var m = sj.getMonth() + 1
      var d = sj.getDate()
      var w = sj.getDay()
      var y1 = sj1.getFullYear()
      var m1 = sj1.getMonth() + 1
      var d1 = sj1.getDate()
      var w1 = sj1.getDay()
      var day = Math.abs((sj.getTime() - sj1.getTime()) / 1000 / 60 / 60 / 24)
      console.log(t , "==============>",sj,t1,  "==============>",sj1,day)
      return day
    },
  },
}

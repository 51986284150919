var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.close}},[_c('el-form',{ref:"form",staticClass:"demo-ruleForm",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"是否同意"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.list),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),(!_vm.swith)?_c('el-form-item',{attrs:{"label":"部门","prop":"spDid","rules":{
            required: true,
            message: '请选择部门',
            trigger: 'change',
          }}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择部门"},on:{"change":_vm.change},model:{value:(_vm.form.spDid),callback:function ($$v) {_vm.$set(_vm.form, "spDid", $$v)},expression:"form.spDid"}},_vm._l((_vm.engList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1):_vm._e(),(!_vm.swith)?_c('el-form-item',{attrs:{"label":"主管","prop":"spUid","rules":{
            required: true,
            message: '请选择主管',
            trigger: 'change',
          }}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"clearable":"","placeholder":"请选择主管"},model:{value:(_vm.form.spUid),callback:function ($$v) {_vm.$set(_vm.form, "spUid", $$v)},expression:"form.spUid"}},_vm._l((_vm.curcityist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1):_vm._e(),(_vm.swith)?_c('el-form-item',{attrs:{"label":"拒绝原因"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"textarea","rows":2,"maxlength":"120","show-word-limit":"","placeholder":"请输入内容"},model:{value:(_vm.form.failReason),callback:function ($$v) {_vm.$set(_vm.form, "failReason", $$v)},expression:"form.failReason"}})],1):_vm._e()],1),_c('div',[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"disabled":_vm.btnDisab,"type":"primary"},on:{"click":_vm.adduser}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getplanInfo } from "@/api/maintenance";
import Viewtimeline from "../../../../components/viewtimeline.vue";
export default {
  components: {
    Viewtimeline,
  },
  data() {
    // 开始时间
    return {
      formsdes: {
        name: "",
        startTime: "",
        endTime: "",
        period: "",
        count: "",
        respUid: "",
      },
      dateLists: {}, //维保时间线的数据
      myDate: [], //维保时间线的时间数据
      coordinate: [], //坐标
      // 网点维保数组
      contentList: [],
      // 游离设备的数据
      freeEquipments: [],
    };
  },
  created() {
    this.getplanInfo({ id: this.$route.query.id }); //调取详情接口
  },
  methods: {
    //tab表头样式
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:30px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;";
      }
    },
    // 获取详情数据
    getplanInfo(row) {
      _getplanInfo(row).then((res) => {
        if (res.code == "1") {
          this.formsdes = Object.assign(res.data);
          this.formsdes.dateLists.forEach((item) => {
            this.coordinate.push(item.coordinate); //时间轴坐标数据
            this.myDate.push(item.startTime); //时间轴时间数据
          });

          this.formsdes.endTime = `${res.data.endTime}` + "T00:00:00.000Z";
          this.formsdes.startTime = `${res.data.startTime}` + "T00:00:00.000Z";
          //  调取时间轴
          this.$refs.viewtimeline.init(
            this.formsdes.startTime,
            this.formsdes.endTime,
            this.myDate,
            this.coordinate
          );
          res.data.contentList.forEach((item) => {
            var datas = [];
            item.serviceList.forEach((oneitem) => {
              datas.push(oneitem);
            });
            item.serviceList = [{ datas: datas }];
          });
          this.contentList = res.data.contentList;
          this.freeEquipments = res.data.freeEquipments;
        }
      });
    },

    // 返回按钮事件
    goBack() {
      this.$router.push("/main/maintenance/plan");
    },
  },
};

//
//
//
//
//

export default {
  name: "shouwTimeLine",
  data() {
    return {
      disabShow: false,
      viewBtn: "",
      myDate: [],
      newday: "",
      diff: "",
      values: "",
      myBl: [],
      myCirclews: [],
      x: "",
      y: "",
      sdata: "",
      edata: "",
      cnum: 0,
      widths: 2000,
    };
  },
  created() {
    this.widths = document.body.clientWidth-500;
  },
  methods: {
    init(startdate,enddate,myDate,myBl) {
      this.disabShow = true;
      this.myDate = myDate;
      this.myBl = myBl;
      this.startdate = startdate;
      this.enddate = enddate;
      this.diff = parseInt(this.timeDiff(this.enddate, this.startdate)); //次数不能多余总长度
      this.draw_listen(
        this.myDate,
        this.myBl,
        this.startdate,
        this.enddate,
        this.diff
      );
    },
    draw_listen(myDate, myBl, startdate, enddate, diff) {
      let that = this;
       this.$emit("timedatas", myDate);
      this.$emit("myBls", myBl);
      var c = document.getElementById("myCanvass"); ///获取画布id为myCanvas
      c.height = c.height;
      c.width = this.widths;
      var ctx = c.getContext("2d"); //获取绘图2D环境
      var myCirclew = [];
      for (var i = 0; i < myDate.length; i++) {
        var w = 75+(this.widths - 150)*myBl[i];
        var h = 50;
        ctx.strokeStyle = "#2B90FA"; //线段颜色
        ctx.lineWidth = 3;
        ctx.arc(w, h, 5, 0, 2 * Math.PI, true); //创建弧/曲线（用于创建圆形或部分圆）
        ctx.fillStyle = "#2B90FA";
        ctx.fill();
        ctx.stroke();
        ctx.font = "12px sans-serif";
        ctx.fillStyle = "#000000";
        if (i % 2 == 0) {
          ctx.fillText(myDate[i], w, 75);
        } else {
          ctx.fillText(myDate[i], w, 30);
        }
        myCirclew.push(w);
      }
      this.myCirclews = myCirclew;
      ctx.beginPath();
      ctx.moveTo(75, 50);
      ctx.lineTo(that.widths-75, 50);
      ctx.stroke();
      //给canvas添加点击事件
    },
    //   转换时间的方法
    timeDiff(t, t1) {
      var sj = new Date(t);
      var sj1 = new Date(t1);
      var y = sj.getFullYear();
      var m = sj.getMonth() + 1;
      var d = sj.getDate();
      var w = sj.getDay();
      var y1 = sj1.getFullYear();
      var m1 = sj1.getMonth() + 1;
      var d1 = sj1.getDate();
      var w1 = sj1.getDay();
      var day = Math.abs((sj.getTime() - sj1.getTime()) / 1000 / 60 / 60 / 24);
      return day;
    },
  },
};

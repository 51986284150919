//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BarEcharts from './BarEcharts.vue'
import LineEcharts from './LineEcharts.vue'
import PieEchartsA from './PieEchartsA.vue'
import PieEchartsB from './PieEchartsB.vue'
import { _serviceDept, _scansuperiordept, _count } from '@/api/check.js'
export default {
  components: {
    BarEcharts,
    LineEcharts,
    PieEchartsA,
    PieEchartsB,
  },
  data() {
    return {
      // // 结束日期不能大于开始日期
      // pickerOptions: {
      //   disabledDate: (time) => {
      //     return time.getTime() > new Date().getTime();
      //   },
      // },
      // // 开始日期大于结束日期
      // newPickerOptions: {
      //   disabledDate: (time) => {
      //     if (this.formInline.endTime) {
      //       return time.getTime() > new Date(this.formInline.endTime).getTime()
      //     }
      //     return time.getTime() > new Date().getTime() - 86400000;
      //   },
      // },
      datas: [],
      departmentData: [],
      formInline: {
        did: '',
        startTime: '',
        endTime: '',
      },
      applyData: [],
      gradeData: [],
      TypeData: [],
    }
  },
  created() {
    // this.serviceDept(this.formInline)
    this.scansuperiordept()
    this.count()
  },
  methods: {
    serviceDept(from) {
      _serviceDept(from).then((res) => {
        this.applyData = res.data.Apply
        this.gradeData = res.data.Grade
        this.TypeData = res.data.Type
      })
    },
    // 获取下拉框部门数据
    scansuperiordept() {
      _scansuperiordept({}).then((res) => {
        this.departmentData = res.data

        //下面新加的
        this.formInline = { ...this.formInline, did: this.departmentData[0].id }
        this.serviceDept(this.formInline)
      })
    },
    count() {
      _count({}).then((res) => {
        this.datas = res.data.reportNum[0]
      })
    },
    query() {
      this.serviceDept(this.formInline)
    },
  },
}

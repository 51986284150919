//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from "echarts";
import { _sector ,_scansuperiordept} from "@/api/check.js";

export default {
  data() {
    return {
       formInline: {
         did: "",
        startTime: "",
        endTime:"",
      },
      serveData:[],
      timeData:[],
      departmentData:[]
    };
  },
  created() {
    // this.sector(this.formInline);
    this.scansuperiordept()
  },
  methods: {
    inits() {
      let chartDom = document.getElementById("mysCharts");
      let myChart = echarts.init(chartDom);
      myChart.clear();
      myChart.setOption({
        tooltip: {
          //提示性文本消息
          trigger: "axis",
          axisPointer: {
            label: {
              show: true,
              backgroundColor: "#fff",
              color: "#556677",
              borderColor: "rgba(0,0,0,0)",
              shadowColor: "rgba(0,0,0,0)",
              shadowOffsetY: 0,
            },
            lineStyle: {
              width: 0,
            },
          },
          backgroundColor: "#fff",
          textStyle: {
            color: "#5c6c7c",
          },
          padding: [10, 10],
          extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)",
        },
        legend: {
          type: "plain",
          bottom: "bottom",
        },
        grid: {
          //图表位置
          left: "4%",
          right: "4%",
          height: "70%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.timeData,
        },
        yAxis: {
          type: "value",
        },
        series: this.serveData,
      });
    },
    sector(from) {
      this.serveData = [];
      this.timeData = [];
      _sector(from).then((res) => {
        this.timeData = res.data.map((item)=>{//聚合X轴
          return item.dept
        })
        res.data.map((item)=>{//聚合大数据
          this.serveData = []
          item.data.forEach((iovs)=>{
            this.serveData.push(
              {
            data: [],
            type: "bar",
            barMaxWidth: 60,
            stack: "total",
            name: iovs.name,
          },
            )
          })
        })
         this.serveData.forEach((item)=>{
           res.data.forEach((iovs)=>{
             iovs.data.forEach((deps)=>{
               if(item.name == deps.name){
                 item.data.push(deps.value)
               }
             })
           })
         })
        this.inits();
      });
    },
       // 获取下拉框部门数据
    scansuperiordept() {
      _scansuperiordept({}).then((res) => {
        this.departmentData = res.data
        //下面新加的
        this.formInline = {...this.formInline , did : this.departmentData[0].id}
        this.sector(this.formInline);
      });
    },
    query() {
      this.sector(this.formInline)
    },
  },
};
